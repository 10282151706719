<template>
    <div class="root_info_user">
        <div class="root_video_live" style="margin-top: 30px;">
            <h2 class="title_category title_category0"><font-awesome-icon icon="fa-solid fa-video" /> <span>Video</span></h2>
            <div class="row" v-if="datas.length > 0">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'VideoPlay_nm', params: { id: item.id } }">
                            <div class="img_live img_live_video">
                                <!-- <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt=""> -->
                                <video ref="video" :src="api_linkUploads + '/videos/' + item.video_path" :data_id="item.id" height="120" :class="'videoplay_hover videoplay_hover'+item.id" muted @mouseover="playHover(index)" @mouseleave="pauseHover(index)"></video>
                                <!-- <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div> -->
                            </div>
                            <div class="text_item_live text_item_live1">
                                <div class="img_live_small">
                                    <img :src="api_linkUploads + '/thumbnail/' + item.thumbnail_path" alt="">
                                </div>
                                <div class="text_live_small videoLeft">
                                    <h3>{{ item.fullname }}</h3>
                                    <!-- <p v-if="item.title.length < 20">{{ item.title }}</p>
                                    <p v-else>{{ item.title.slice(0,20) }}...</p> -->
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="text_video_preview">
                        <router-link v-bind:to="{ name: 'VideoPlay_nm', params: { id: item.id } }">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.description }}</p>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'Video_nm',
    data () {
        return {
            listVideo: [],
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            videosState: {}
        }
    },
    components: {
    },
    created () {
        this.getListVideoAll()
    },
    computed: {
        
    },
    methods: {
        getListVideoAll () {
            this.axios.get(this.api_listvideoidol).then((response) => {
                this.listVideo = response.data.items
                // console.log('1111', this.listVideo)
                this.totalCount = this.listVideo.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listVideo.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        playHover (idx) {
            // console.log(e.target.getAttribute('data_id'));
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            if (!isPlaying) {
                video.play();
            } else {
                video.pause();
            }
            this.videosState[idx].play = !isPlaying
        },
        pauseHover (idx) {
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            video.pause();
            this.videosState[idx].play = !isPlaying
        }
    }
}
</script>
<style>
</style>
